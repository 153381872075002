import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Blobik2 = makeShortcode("Blobik2");
const LinkedinIcon = makeShortcode("LinkedinIcon");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Blobik2 className="h-12 w-12" mdxType="Blobik2" />
    <h1>{`Blanka Opekarová`}</h1>
    <p><a parentName="p" {...{
        "href": "mailto:blanka.opekarova@gmail.com"
      }}>{`blanka.opekarova@gmail.com`}</a><br parentName="p"></br>{`
`}{`Tel: 603 404 120`}<br parentName="p"></br>{`
`}{`IČO: 9403302  `}</p>
    <h1></h1>
    <p><a parentName="p" {...{
        "href": "https://www.linkedin.com/in/blanka-opekarov%C3%A1-8967a5157"
      }}><LinkedinIcon className="h-8 w-8 inline" mdxType="LinkedinIcon" />{` BLOP na Linkedin`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      