import React from "react";
import { Helmet } from "react-helmet";
import Layout from '../components/layout'
import ContactText  from "../texty/contact.mdx"
import Blobik2 from "../images/blobik2.svg";


export default function Contact() {
  return (
    <Layout>
      <Helmet>
        <title>Blanka Opekarova</title>
        <meta name="description" content="Blanka Opekarova" />
      </Helmet>

      <div className="flex mb-4 flex-wrap">

        <div >
          <ContactText/>
        </div>

      </div>

     </Layout>
  )
}
